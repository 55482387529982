/**
 * Wrapper to aid in initialising Leaflet maps
 */
export default class Map {
    constructor(element, lat, long){
        this.initMap(element, lat, long);
    }

    initMap(element, lat, long) {
        this.map = L.map(element).setView([lat, long], 15);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
    }

    addMarker(latitude, longitude) {
        L.marker([latitude, longitude]).addTo(this.map);
    }
}