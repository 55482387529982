import Map from './map';

class JSMap {
    constructor() {
        this.selector = '.js-map';

        let maps = document.querySelectorAll(this.selector);

        for(let map of maps) {
            let mapObj = new Map(map, map.dataset.lat, map.dataset.long);
            mapObj.addMarker(map.dataset.lat, map.dataset.long);
        }
    }
}

export default JSMap;