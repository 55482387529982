class GoBack {
    constructor()
    {
        this.links = document.querySelectorAll('.js-go-back');

        this.addEventsListeners();
    }

    addEventsListeners()
    {
        this.links.forEach(link => {
            link.addEventListener('click', e => {
                e.preventDefault();
                window.history.back();
            });
        });
    }
}

export default GoBack;