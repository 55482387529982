window.td_menu = function() {
    return {
        showMenu: false,
        openMenus: [],
        showBackground: false,

        toggleMenu(menuItemID, parentID) {
            if (!this.isMenuOpen(menuItemID)) {
                if (parentID == 0) {
                    this.closeAllMenus();
                }

                this.openMenus = this.openMenus.concat({
                    id: menuItemID,
                    parent: parentID,
                });
            } else {
                // Close the current menu item and it's children
                this.openMenus = this.openMenus.filter(openMenuItem => {
                    return (openMenuItem.parent != menuItemID) && (openMenuItem.id != menuItemID);
                });
            }
        },

        init() {
            this.$watch('showMenu', (val) => {
                if (val) {
                    document.body.classList.add('-hide-overflow');
                } else {
                    this.closeAllMenus();
                    document.body.classList.remove('-hide-overflow');
                }
            });

            this.$watch('openMenus', () => {
                this.maybeAddBackground();
            });

            this.checkScroll();
            this.addMediaQuery();
        },

        /**
         * Close open submenus when we drop to the mobile menu
         *
         * @return  {void}
         */
        addMediaQuery() {
            const mediaQuery = window.matchMedia("(max-width: 999px)");

            mediaQuery.addEventListener('change', e => {
                if (e.matches) {
                    this.closeAllMenus();
                }
            })
        },

        closeAllMenus() {
            this.openMenus = [];
        },

        isMenuOpen(menuID) {
            return this.openMenus.some(openMenuItem => openMenuItem.id === menuID);
        },

        isAnyMenuOpen() {
            return this.openMenus.length;
        },

        onEscape(e) {
            this.closeAllMenus();

            let topLevelButton = e.currentTarget.querySelector('button');
            if(topLevelButton){
                topLevelButton.focus();
            }
        },

        onClickAway(e) {
            if(!e.target.classList.contains('dropdown')) {
                this.closeAllMenus();
            }
        },

        checkScroll(){
            this.maybeAddBackground();
        },

        maybeAddBackground() {
            this.showBackground = window.scrollY > 0 || this.isAnyMenuOpen();
        }
    }
}