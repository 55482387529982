// import Swiper JS
import Swiper, { Navigation } from 'swiper';
// import Swiper styles
import 'swiper/css';

class SwiperSliders {
    constructor()
    {
        if (!document.querySelector('.swiper')) {
            return;
        }

        this.breakpoint = window.matchMedia('(min-width: 900px)');
        this.breakpoint.addEventListener('change', () => this.maybeDestroySlider());

        this.maybeDestroySlider();
    }

    maybeDestroySlider() {
        if (this.breakpoint.matches === true) {
            if (this.slider !== undefined) {
                this.slider.destroy(true, true);
            }

            return;
        } else if (this.breakpoint.matches === false) {
            return this.initSlider();
        }
    }

    initSlider() {
        this.slider = new Swiper('.swiper', {
            slidesPerView: 1.2,
            spaceBetween: 24,
            modules: [
                Navigation
            ],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.2
                }
            }
        });
    }

}

export default SwiperSliders;